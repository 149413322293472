// @flow
import * as React from "react";

import AuthService from "services/auth";

import Navigation from "./Navigation";

const MAIN_SITE_ORIGIN = process.env.REACT_APP_MAIN_SITE_ORIGIN;

const Navbar = {
  LoggedIn: function (): React.Node {
    const claims = AuthService.getClaims();

    const handleLogout = () => {
      AuthService.logout();

      window.location.replace(`${MAIN_SITE_ORIGIN}/login`);
    };

    switch (claims.rol) {
      case "adm":
      case "him":
      case "ham":
      case "inm":
      case "afm":
        return (
          <React.Fragment>
            <Navigation.Manager />
            <div className="ms-auto">
              <a href={`${MAIN_SITE_ORIGIN}/control-panel/users/${claims.id}`} className="me-2">
                {claims.name}
              </a>
              <button className="btn btn-primary btn-sm" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </React.Fragment>
        );
      case "aff":
        return (
          <React.Fragment>
            <Navigation.Affiliate />
            <div className="ms-auto">
              <a href={`${MAIN_SITE_ORIGIN}/affiliates/${claims.id}`} className="me-2">
                ({claims.id}) {claims.name}
              </a>
              <button className="btn btn-primary btn-sm" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </React.Fragment>
        );
      default:
        return null;
    }
  },
  LoggedOut: function (): React.Node {
    return (
      <React.Fragment>
        <div className="ms-auto">
          <a href={`${MAIN_SITE_ORIGIN}/login`} className="btn btn-primary me-2">
            Login
          </a>
          <a href={`${MAIN_SITE_ORIGIN}/registration`} className="btn btn-success">
            Registration
          </a>
        </div>
      </React.Fragment>
    );
  },
};

export default Navbar;
